import {ReactNode} from "react";
import {ArLoad} from "./Component";
import {Input, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {ProColumns} from "@ant-design/pro-components";
import {ColumnsType} from "antd/es/table";
import moment from "moment";

export interface IValueEnum {
  [key: string]:
    | ReactNode
    | {
        text: ReactNode;
        status: "Success" | "Error" | "Processing" | "Warning" | "Default";
      };
}

export const statusValueEnum: IValueEnum = {
  connect: {
    text: "连接中",
    status: "Processing",
  },
  "connect.success": {
    text: "连接成功",
    status: "Success",
  },
  "connect.fail": {
    text: "连接失败",
    status: "Error",
  },
  "connect.disconnect": {
    text: "连接断开",
    status: "Error",
  },
  upload: {
    text: "文件上传中",
    status: "Processing",
  },
  "upload.success": {
    text: "上传完成",
    status: "Success",
  },
  "upload.fail": {
    text: "上传失败",
    status: "Error",
  },
  render: {
    text: "渲染中",
    status: "Processing",
  },
  "render.success": {
    text: "渲染成功",
    status: "Success",
  },
  "render.fail": {
    text: "渲染失败",
    status: "Error",
  },
};

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

export interface fileListType {
  /**修改时间 */
  time: number;
  /**文件大小（kB） */
  size: number;
  /**文件名 */
  name: string;
}

export const fileColumns: ColumnsType<fileListType> = [
  {
    title: "文件名",
    dataIndex: "name",
  },
  {
    title: "创建时间",
    dataIndex: "time",
    render: (value, record) => moment(record.time).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "大小",
    dataIndex: "size",
    render: (value, record) => record.size + "KB",
  },
];

export interface server {
  ip: string;
  port: string;
  password: string;
}

export interface dsType {
  /**服务器Endpoint */
  endpoint?: string;
  status?: keyof IValueEnum;
  serverId?: string;
  startFrame?: number;
  endFrame?: number;
  log?: string | ReactNode;
  duration?: number;
}

export interface startForm {
  endFrame: number;
  fileName: string;
  format: string;
  servers: Array<server>;
  startFrame: number;
  upload;
}

export interface taskType {
  /**开始帧 */
  start: number;
  /**结束帧 */
  end: number;
}
