import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import React, {useEffect, useState, lazy, Suspense} from "react";
import Error from "./error";
import {Layout, Menu, Watermark, Input, Typography, Row, Col, Divider, Space, message, Modal} from "antd";
import {GithubOutlined, GlobalOutlined} from "@ant-design/icons";
import Home from "./Home";
import Start from "./Start";
import consoleDialog from "./console";
import Download from "./Downloadpage";
import "./App.css";
import Aegis from "aegis-web-sdk";
import {uuid} from "./plug";
import {RecoilRoot} from "recoil";
const {Content, Footer} = Layout;
const {Title} = Typography;

function Main() {
  const navigate = useNavigate();

  return (
    <>
      <Watermark content="Ar-Sr-Na 测试版本">
        <Layout style={{minHeight: "100vh"}}>
          <div className="nav-blur">
            <img height="60" src={require("./logo.png")} style={{paddingRight: 5}} alt="logo" />
            <span className="lead">ArRM集群渲染管理器</span>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[""]}
              style={{marginLeft: 30, background: "rgba(0,0,0,0)"}}
              items={[
                {
                  label: "首页",
                  key: "",
                },
                {
                  label: "开始一个工程",
                  key: "start",
                },
                {
                  label: "下载",
                  key: "download",
                },
              ]}
              onClick={e => navigate(e.key)}></Menu>
          </div>
          <Content style={{padding: "80px 20px 0px 20px"}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/start" element={<Start />} />
              <Route path="/download" element={<Download />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Content>
          <Footer>
            <FooterContent />
          </Footer>
        </Layout>
      </Watermark>
    </>
  );
}

function FooterContent() {
  return (
    <>
      <Divider />
      <Row gutter={16}>
        <Col span={8}>
          <Space direction="vertical">
            <Title level={4}>ArRM</Title>
            <a target="_blank" href="https://www.arsrna.cn/app/render/" rel="noreferrer">
              产品介绍
            </a>
            <a target="_blank" href="https://www.arsrna.cn/app/render/#download" rel="noreferrer">
              下载
            </a>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction="vertical">
            <Title level={4}>开发</Title>
            <a target="_blank" href="https://www.arsrna.cn/" rel="noreferrer">
              <GlobalOutlined /> Ar-Sr-Na 网站
            </a>
            <a target="_blank" href="https://www.arsrna.cn/app/" rel="noreferrer">
              <GlobalOutlined /> 资源与应用中心
            </a>
            <a target="_blank" href="https://github.com/ArSrNa/" rel="noreferrer">
              <GithubOutlined /> github
            </a>
            <a target="_blank" href="https://www.arsrna.com/contact/" rel="noreferrer">
              <GithubOutlined /> 联系我们
            </a>
          </Space>
        </Col>

        <Col span={8}>
          <Space direction="vertical">
            <Title level={4}>更多</Title>
            <a target="_blank" href="https://www.arsrna.com/app/esrgan/" rel="noreferrer">
              ESRGANUI 超分辨率
            </a>
            <a target="_blank" href="https://www.arsrna.cn/app/catch/" rel="noreferrer">
              异步采集
            </a>
            <a target="_blank" href="https://ai.arsrna.cn/" rel="noreferrer">
              AI 中心
            </a>
            <a target="_blank" href="https://live.arsrna.cn/" rel="noreferrer">
              音视频
            </a>
          </Space>
        </Col>
      </Row>
      <div
        style={{
          paddingTop: 20,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}>
        <a target="_blank" href="https://beian.miit.gov.cn/">
          沪ICP备2023039698号-1
        </a>
        <Divider type="vertical" />
        Powered by Ar-Sr-Na
        <Divider type="vertical" />
        上海绫中信息技术有限公司
      </div>
    </>
  );
}

function CheckUpdate() {
  const count = 1;
  fetch("https://api-gz.arsrna.cn/release/appUpdate/ArRM_C")
    .then(msg => msg.json())
    .then(msg => {
      console.log(msg);
      if (msg.count > count) {
        Modal.info({
          title: "版本更新提示",
          content: (
            <>
              <b>
                有新版本 {msg.rName} {msg.vNumber}
              </b>
              <hr />
              {msg.content}
            </>
          ),
          onOk() {
            window.open(msg.link);
          },
          okText: "前往下载",
          closable: true,
        });
      } else {
        message.info("已是最新版本");
      }
    });
}

function App() {
  useEffect(() => {
    CheckUpdate();
    consoleDialog();
  }, []);
  const aegis = new Aegis({
    id: "W7oejTaL4r0ek1Yg67", // 上报 id
    uin: `ArRMClient ${uuid()}`, // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 应用页面跳转的时候开启 pv 计算
    hostUrl: "https://rumt-zh.com",
  });
  return (
    <RecoilRoot>
      <Router>
        <Main />
      </Router>
    </RecoilRoot>
  );
}

export default App;
