import {atom} from "recoil";
import {dsType, server, startForm, taskType} from "./type";
import {Socket} from "socket.io-client";

export const pconfigState = atom<startForm>({
  key: "pconfig",
  default: null,
});

export const wscState = atom<Socket[]>({
  key: "wsc",
  default: [],
  dangerouslyAllowMutability: true,
});

export const dataSourceState = atom<dsType[]>({
  key: "dataSource",
  default: [],
  dangerouslyAllowMutability: true,
});
