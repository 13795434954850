export default function consoleDialog() {
    console.log('______________________________________');
    console.log('表示旁白');
    console.log('%c表示芽衣', 'color:red');
    console.log('%c表示爱莉希雅', 'color:pink');
    console.log('______________________________');
    console.log('%c芽衣芽衣，看这里！', 'color:pink');
    console.log('%c嗯······就站在那儿，不要动哦。', 'color:pink');
    console.log('%c······？', 'color:red');
    console.log('粉发的少女一反常态，没有更近一步，只是不停地看向这里，不时低下头在纸上裁剪涂画。');
    console.log('%c喔！做好啦！', 'color:pink');
    console.log('%c这是······做成我的样子的，纸模小人？', 'color:red');
    console.log('%c嗯！接下来就是最后一步，把芽衣的小人放进这本乐土立体书里······啊······', 'color:pink');
    console.log('%c怎么了？', 'color:red');
    console.log('%c唔，还是算了吧。不能对芽衣做这种事呢。', 'color:pink');
    console.log('%c芽衣······终究是要离开乐土的。毕竟你和我们不一样嘛。', 'color:pink');
    console.log('芽衣还是第一次在爱莉希雅脸上看见这样的神情——温柔、甜美，却略带寂寞的微笑。');
    console.log('但这抹神情转瞬即逝。眨眼间，少女已恢复了平日的神采。')
    console.log('%c既然如此，这本书就送给芽衣吧！少女的心意，可要心怀感激地收下哟。', 'color:pink');
    console.log('%c如何如何？我可是拜托了好多人和我一起做这个的！', 'color:pink');
    console.log('%c从封底的制作人员表里来看，确实是很多人呢······', 'color:red');
    console.log('%c倒是的确很精致，每一页的情境都很还原，甚至能唤起鲜明的回忆······等等，爱莉希雅，你该不会从一开始就准备把这本书······', 'color:red');
    console.log('%c嗯哼♪', 'color:pink');
    console.log('%c——谢谢，我会好好珍惜的。', 'color:red');
    console.log('%c哇，这可真是······这么直率的芽衣好难得哦！', 'color:pink');
    console.log('%c既然如此，能不能再让我摸摸你的······哎呀，开玩笑的，不要跑嘛♪', 'color:pink');
    console.log('______________________________________');

    console.log('Powered by', 'Ar-Sr-Na', 'ArRM集群渲染管理器');
}