import {
  Card,
  Button,
  Space,
  Upload,
  message,
  Popconfirm,
  Tooltip,
  Statistic,
  Row,
  Col,
  Select,
  Table,
  Image,
  Input,
  InputNumber,
} from "antd";
import {InboxOutlined, QuestionCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {
  ProCard,
  ProForm,
  ProFormText,
  ProFormSelect,
  StepsForm,
  ProFormList,
  ProFormDigit,
  ProTable,
  ProFormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import {useRef, useState, useEffect} from "react";
import md5 from "md5";
import {ArLoad} from "./Component";
const {Dragger} = Upload;
import {io, Socket} from "socket.io-client";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {dataSourceState, pconfigState, wscState} from "./states";
import {dsType, fileColumns, fileListType, startForm, statusValueEnum, taskType} from "./type";
import FormItem from "antd/es/form/FormItem";
import {TableRowSelection} from "antd/es/table/interface";

export default function Start(props) {
  const formRef = useRef<ProFormInstance>(null);
  const setDataSource = useSetRecoilState(dataSourceState);
  const [files, setFiles] = useState(null);
  const setWsc = useSetRecoilState(wscState);
  const [pconfig, setPConfig] = useRecoilState(pconfigState);
  const [finish, setFinish] = useState(false);

  function changeColumn(i: number, obj: dsType) {
    setDataSource(prev => {
      const arr = [...prev];
      Object.keys(obj).forEach(k => (arr[i][k] = obj[k]));
      return arr;
    });
  }

  useEffect(() => {
    if (localStorage.config !== void "我永远喜欢菲谢尔") {
      formRef.current.setFieldsValue(JSON.parse(localStorage.config));
    }
  }, []);

  async function connect(e: startForm) {
    const {servers, startFrame, endFrame, format, upload, fileName} = e;
    console.log("pconfig", e);
    var tasks = spliceTask(servers.length, startFrame, endFrame);

    let ds = servers.map((svr, i) => ({
      endpoint: `${svr.ip}:${svr.port}`,
      status: "connect",
      startFrame: tasks[i].start,
      endFrame: tasks[i].end,
    }));
    // console.log(ds);
    setDataSource(ds);

    let ios: Socket[] = servers.map((svr, i) => {
      return io(`ws://${svr.ip}:${svr.port}/`, {
        extraHeaders: {
          password: md5(svr.password),
        },
        // autoConnect: false,
      });
    });

    setWsc(ios);
    console.log(ios);

    ios.forEach((ws, i) => {
      ws.on("connect", () => {
        changeColumn(i, {status: "connect.success", serverId: ws.id});
      });
      ws.io.on("reconnect_attempt", () => {
        changeColumn(i, {status: "connect"});
      });
      ws.io.on("reconnect", () => {
        changeColumn(i, {status: "connect"});
      });
      ws.on("connect_error", () => {
        changeColumn(i, {status: "connect.fail"});
      });
      ws.on("disconnect", () => {
        changeColumn(i, {status: "connect.disconnect"});
      });
      ws.on("ArBlender", data => {
        const {success, type, status, log, duration} = data;

        if (!success) {
          changeColumn(i, {status: "render.fail"});
        }
        if (type === "exit") {
          changeColumn(i, {log: "渲染完成，请前往文件管理获取文件。", status: "render.success", duration});
          // ws.disconnect();
        } else {
          changeColumn(i, {status, log, duration});
        }
      });
    });
  }

  return (
    <>
      <Space size="middle" direction="vertical" style={{width: "100%"}}>
        <ProCard title="开始一个新连接" collapsible>
          <StepsForm
            formRef={formRef}
            onFinish={async (e: startForm) => {
              const {servers, startFrame, endFrame, format} = e;
              localStorage.setItem("config", JSON.stringify({servers, startFrame, endFrame, format}));
              connect(e);
              setPConfig(e);
              setFinish(true);
              message.success("保存成功，请下滑继续流程。");
            }}
            formProps={{
              validateMessages: {
                required: "此项为必填项",
              },
            }}>
            <StepsForm.StepForm title="连接服务器" stepProps={{description: "请根据从机端的信息填入"}}>
              <ProFormList name="servers">
                <Space>
                  <ProFormText label="服务器IP" name="ip" rules={[{required: true}]} />
                  <ProFormDigit label="端口" name="port" rules={[{required: true}]} />
                  <ProFormText.Password label="密码" name="password" rules={[{required: true}]} />
                </Space>
              </ProFormList>
            </StepsForm.StepForm>

            <StepsForm.StepForm title="项目设置" stepProps={{description: "根据项目需要，设置好参数"}}>
              <ProForm.Item name="upload" label="选择工程文件" rules={[{required: true}]}>
                <Dragger maxCount={1} customRequest={e => setFiles(e)} showUploadList={false} accept=".blend">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击此处或拖入文件以上传</p>
                  <p className="ant-upload-hint">{files === null ? "暂无文件" : <>已选择：{files.file.name}</>}</p>
                </Dragger>
              </ProForm.Item>

              <Space style={{width: "100%"}}>
                <ProFormDigit label="起始帧" name="startFrame" rules={[{required: true}]} />
                <ProFormDigit label="终止帧" name="endFrame" rules={[{required: true}]} />
              </Space>

              <ProFormText name="fileName" initialValue="####" label="输出文件名（# 为帧号占位符）" />

              <ProFormSelect
                name="format"
                initialValue={"JPEG"}
                label="输出格式"
                options={["JPEG", "TGA", "RAWTGA", "IRIS", "IRIZ", "AVIRAW", "AVIJPEG", "PNG", "BMP"].map(i => ({
                  label: i,
                  value: i,
                }))}
              />
            </StepsForm.StepForm>
          </StepsForm>
        </ProCard>

        <ServerConnections finish={finish} changeColumn={changeColumn} />
        {pconfig !== null && <FileManagement />}
      </Space>
    </>
  );
}

function ServerConnections({finish, changeColumn}) {
  const pconfig = useRecoilValue(pconfigState);
  const dataSource = useRecoilValue(dataSourceState);
  const [wsc, setWsc] = useRecoilState(wscState);

  async function handleStart() {
    const {servers, startFrame, endFrame, format, upload, fileName} = pconfig;
    let 在线机器 = wsc.filter(svr => svr.active);
    console.log(在线机器);
    if (在线机器.length === 0) {
      message.warning("仍有机器未在线，请重新连接！");
      return;
    }
    console.log(wsc.map(svr => svr.active));
    for (let i in wsc) {
      const svr = wsc[i];
      if (!svr.active) return;
      //仅当服务器在线时操作渲染
      const formdata = new FormData();
      formdata.append("file", upload.file.originFileObj);
      changeColumn(i, {status: "upload"});
      try {
        let msg = await fetch(`http://${servers[i].ip}:${servers[i].port}/upload`, {
          method: "post",
          body: formdata,
          headers: {password: md5(servers[i].password)},
        }).then(msg => msg.text());

        console.log(msg);
        changeColumn(i, {status: "upload.success"});
        svr.emit("ArBlender", {
          command: true,
          format,
          startFrame: dataSource[i].startFrame,
          endFrame: dataSource[i].endFrame,
          fileName,
        });
      } catch (err) {
        message.error(`上传失败！${err}`);
        changeColumn(i, {status: "upload.fail"});
      }
    }
  }

  const machineColumns: ProColumns<dsType, "text">[] = [
    {
      title: "服务器Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
      width: 150,
    },
    {
      title: "状态",
      dataIndex: "status",
      width: 100,
      key: "status",
      valueEnum: statusValueEnum,
    },
    {
      title: (
        <>
          serverId{" "}
          <Tooltip title="请检查从机端ClientId处是否与此处相等，相等意味着两端已同步">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "serverId",
      key: "serverId",
      width: 200,
    },
    {
      title: "开始",
      dataIndex: "startFrame",
      key: "startFrame",
      width: 80,
      render: (dom, entity, index) => (
        <InputNumber onInput={e => changeColumn(index, {startFrame: e})} value={entity.startFrame} />
      ),
    },
    {
      title: "结束",
      dataIndex: "endFrame",
      key: "endFrame",
      width: 80,
      render: (dom, entity, index) => (
        <InputNumber onInput={e => changeColumn(index, {endFrame: e})} value={entity.endFrame} />
      ),
    },
    {
      title: "计时",
      dataIndex: "duration",
      key: "duration",
      width: 80,
      render: (dom, entity, i) => `${entity.duration / 1000}s`,
    },
    {
      title: "日志",
      dataIndex: "log",
      key: "log",
      width: "40%",
      render: (v, r, i) => (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}>
          {r.log == null ? "等待启动" : r.log}
        </div>
      ),
    },
  ];

  return (
    <Card
      title="连接状态与预览"
      styles={{
        header: {
          position: "sticky",
          top: 60,
          backdropFilter: "blur(20px)",
          background: "rgba(255,255,255,0.7)",
          zIndex: 5,
        },
      }}
      extra={
        <Popconfirm
          title="！！！确认开始？"
          description={
            <>
              技术限制，暂时无法实现终止功能，请确认后再启动！！！
              <br />
              只能发送给联机的机器，如果不显示联机，将无法开始，请返回删除。
              <br />
              <b style={{color: "red"}}>过程中禁止关闭浏览器页面！！！也不要切换任何页面，否则流程无法正常进行！！！</b>
            </>
          }
          onConfirm={handleStart}>
          <Button type="primary" disabled={!finish}>
            开始渲染
          </Button>
        </Popconfirm>
      }>
      <Space size="middle" direction="vertical" style={{width: "100%"}}>
        <ProTable
          rowKey="startFrame"
          search={false}
          toolBarRender={false}
          columns={machineColumns}
          dataSource={dataSource}
        />
      </Space>
    </Card>
  );
}

function FileManagement() {
  const ioServers = useRecoilValue(wscState);
  const {servers} = useRecoilValue(pconfigState);
  const onlineServers = ioServers.filter(f => f.active);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [current, setCurrent] = useState(0);
  const [list, setList] = useState<fileListType[]>(null);
  const [src, setSrc] = useState<fileListType>(null);

  const rowSelection: TableRowSelection<fileListType> = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  async function getFileList(i: number) {
    const svr = servers[i];
    const {ip, port, password} = svr;
    try {
      return (
        await fetch(`http://${ip}:${port}/res/list`, {
          method: "post",
          headers: {
            password: md5(password),
          },
        }).then(msg => msg.json())
      ).data;
    } catch (err) {
      return [];
    }
  }

  async function downloadSelect() {
    const {ip, port, password} = servers[current];
    console.log(selectedRowKeys);
    const file = await fetch(`http://${ip}:${port}/res/download`, {
      method: "post",
      headers: {
        password: md5(password),
        "content-type": "application/json",
      },
      body: JSON.stringify({files: selectedRowKeys}),
    }).then(msg => msg.blob());
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = `arrm_result_${servers[current].ip}.zip`;
    link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
    link.click();
  }

  return (
    <Card title="文件管理" extra={<>可操作机器数：{onlineServers.length}</>}>
      <Row gutter={64}>
        <Col span={8}>
          <FormItem label="选择一台从机" layout="vertical">
            <Select
              onChange={e => {
                setCurrent(e);
                getFileList(e).then(setList);
              }}
              options={servers.map((svr, i) => ({label: `${svr.ip}:${svr.port}`, value: i}))}
            />
            <Button block onClick={() => getFileList(current).then(setList)}>
              刷新
            </Button>
          </FormItem>

          <Space style={{width: "100%"}} direction="vertical">
            {src !== null && (
              <>
                <Image src={`http://${servers[current].ip}:${servers[current].port}/res/${src.name}`} />
                <caption>{src.name}</caption>
              </>
            )}
            <Button disabled={selectedRowKeys.length === 0} onClick={downloadSelect}>
              下载选中的 {selectedRowKeys.length} 张图片
            </Button>
          </Space>
        </Col>
        <Col span={16}>
          {list !== null && (
            <Table
              onRow={record => ({
                onClick: e => {
                  setSrc(record);
                },
              })}
              columns={fileColumns}
              dataSource={list.map(li => ({...li, key: li.name}))}
              rowSelection={rowSelection}></Table>
          )}
        </Col>
      </Row>
    </Card>
  );
}

function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * @author Ar-Sr-Na
 * @description 任务分配
 * @param machines 机器数量
 * @param st 起始帧
 * @param end 总帧
 * @returns 分配任务数组
 */
function spliceTask(machines: number, st: number, end: number): taskType[] {
  let start = st - 1,
    splice = (end - start) / machines,
    tasks = [];
  for (var i = 0; i < machines; i++) {
    tasks.push({
      start: Math.floor(1 + Math.floor(start) + i * splice),
      end: Math.floor(Math.floor(start) + (i + 1) * splice),
    });
  }
  return tasks;
}
