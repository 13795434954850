import {Space, Typography, Steps, Image, Row, Col, Button, Alert, Divider, Table} from "antd";
import React, {useEffect, useState} from "react";
import Error from "./error";
import {io} from "socket.io-client";
import md5 from "md5";
import {Link} from "react-router-dom";
const {Title, Paragraph} = Typography;

export default function Home() {
  const [step, setStep] = useState(0);
  const tourItems = [
    {
      title: "介绍",
      description: "这是干啥用的？",
    },
    {
      title: "安装",
      description: "从机端",
    },
    {
      title: "配置",
      description: "从机端",
    },
    {
      title: "开始",
      description: "控制端配置",
    },
    {
      title: "渲染",
      description: "控制端操作",
    },
  ];
  return (
    <>
      <Space size="middle" style={{width: "100%"}} direction="vertical">
        <div>
          <div style={{zIndex: 1, position: "absolute", padding: 50, color: "white"}}>
            <h1 className="title" style={{letterSpacing: 3}}>
              ArSrNa 集群渲染管理
            </h1>
            <span className="lead">控制端</span>
          </div>
          <img src={require("./images/index.jpg")} className="hdpic" />
        </div>

        <div className="container">
          <section>
            <Title level={1}>快速入门</Title>
            <Paragraph>若您是第一次使用，请先了解功能流程。</Paragraph>
          </section>

          <Row style={{alignItems: "flex-start"}}>
            <Col span={8} style={{position: "sticky", width: "40%", top: 70}}>
              <Steps current={step} onChange={setStep} direction="vertical" items={tourItems} />
              <Divider />
              <Button type="primary" onClick={() => setStep(step - 1)} disabled={step == 0} style={{marginRight: 15}}>
                &lt;
              </Button>
              <Button type="primary" onClick={() => setStep(step + 1)} disabled={step == tourItems.length - 1}>
                &gt;
              </Button>
            </Col>
            <Col span={16}>
              <TourStart step={step} />
            </Col>
          </Row>
        </div>
      </Space>
    </>
  );
}

function TourStart({step}) {
  var dom;
  switch (step) {
    case 0:
      dom = (
        <>
          <h1>介绍</h1>
          <p>
            <a href="https://www.arsrna.cn/app/render/" target="_blank">
              产品介绍
            </a>
            。
          </p>
          <p>总之，就是一个可以将渲染任务分发到其它机器的工具，您所访问的此页面是它的控制端。</p>
          <Image src={require("./images/renderer.jpg")} />
        </>
      );
      break;
    case 1:
      dom = (
        <>
          <h1>下载</h1>
          <Space size="middle" direction="vertical" style={{width: "100%"}}>
            <Divider orientation="left">Blender</Divider>
            <a href="https://www.blender.org/download/" target="_blank">
              下载blender | www.blender.org/download/
            </a>
            <Alert
              message={
                "请确认版本是否符合，只允许用于渲染端的版本高于或等于本地版本。\n建议下载zip，以便更好获得安装目录。"
              }
              type="info"
              showIcon
            />
          </Space>

          <Space size="middle" direction="vertical" style={{width: "100%"}}>
            <Divider orientation="left">ArRM 从机端</Divider>
            <Link to="/download">点此下载</Link>
            <Alert message="blender运行需要一定要求，本软件依赖blender运行。参考下表。" showIcon type="warning" />
            <Table
              columns={[
                {
                  title: "组件",
                  dataIndex: "component",
                  key: "component",
                },
                {
                  title: "最低要求",
                  dataIndex: "requirement",
                  key: "requirement",
                },
              ]}
              dataSource={[
                {
                  component: "GPU",
                  requirement: "2GRAM，支持OpenGL3.3",
                },
                {
                  component: "CPU",
                  requirement: "2GHz，支持SSE2",
                },
                {
                  component: "RAM",
                  requirement: "8GB",
                },
                {
                  component: "磁盘",
                  requirement: "留足1GB以上空间",
                },
              ]}
            />
          </Space>
        </>
      );
      break;
    case 2:
      dom = (
        <>
          <h2>基本设置</h2>
          <p>第一次启动从机端，需要进行下列内容的设置</p>
          <ul>
            <li>blender路径：选择当前电脑安装的 blender.exe 所在路径</li>
            <li>输出目录：用于接收工程文件和渲染后的文件。渲染后文件将在路径下 ./output/ 文件夹里</li>
            <li>监听端口：用于控制端的连接，若默认端口冲突，请修改。</li>
            <li>服务端密码：为保护服务器安全，控制端连接时需要用到此密码。</li>
          </ul>
          <b style={{color: "red"}}>设置完毕后，请保存并重启应用</b>

          <h2>基本使用</h2>
          <p>启动应用后，会在设置的端口上启动监听，服务器IP请前往面板左侧网络信息处查询。</p>
          <p>取同一局域网下的IP，即为服务器IP</p>
          <Image src={require("./images/tour/network.png")}></Image>
        </>
      );
      break;

    case 3:
      dom = (
        <Space direction="vertical" size="middle">
          <h1>开始新连接</h1>
          <p>
            点击顶部{" "}
            <a href="/start" target="_blank">
              开始一个工程
            </a>{" "}
            跳转到对应页面
          </p>
          <section>
            <h2>设置服务器</h2>
            <Image src={require("./images/tour/addserv.png")}></Image>
            <p>在开始一个新连接的位置，点击 添加一行数据 输入服务器IP、端口、密码等信息。然后下一步。</p>
          </section>

          <section>
            <h2>设置工程</h2>
            <Image src={require("./images/tour/project.png")}></Image>
            <p>按照表单提示，上传文件，设置起止帧，输出文件名和输出格式即可</p>
            <Alert
              showIcon
              description="# 会被替代为帧序号，不足位补零。如果文件名不包含 # , 默认为文件名添加后缀 #### ."></Alert>
          </section>
        </Space>
      );
      break;

    case 4:
      dom = (
        <Space direction="vertical" size="middle">
          <h1>开始渲染</h1>
          <p>一切设置完成后，点击提交按钮，并在下方 连接状态与预览 处显示配置。</p>
          <section>
            <h2>检查</h2>
            <Image src={require("./images/tour/preview.png")}></Image>
            <li style={{color: "red"}}>
              过程中禁止关闭浏览器页面！！！必须禁止，一定不能刷新，退出！！！必须留在这个页面，千万不要切换，数据无法保存！！！
            </li>
            <h2 style={{color: "green"}}>
              以上我解决不了；如果真的困扰到大家了，也多多谅解；开发不易，希望各位能够建言献策，目前软件暂未开源，但是任何建议意见都可以
              <a href="https://www.arsrna.com/contact/" target="_blank">
                联系我们
              </a>
            </h2>
          </section>

          <section>
            <h2>开始</h2>
            <p>点击上方开始渲染，确认后即可。</p>
          </section>
        </Space>
      );
      break;
    default:
      dom = <Error />;
      break;
  }

  return dom;
}
